import { Component, OnInit, ViewEncapsulation,Renderer2,NgModule ,TemplateRef,ViewChild} from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EmailService } from '../service-api/email.service';
import { AuthGuard } from '../service-guard/auth.guard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-page-product-detail',
  templateUrl: './page-product-detail.component.html',
  styleUrls: ['./page-product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageProductDetailComponent implements OnInit {
  environment=environment;

  activeImage: any = '';
  error_msg: any = null;
  success_msg: any = null;
  activeSlug:string='';
  product:{
    id: number,
    title: string,
    slug: string,
    description: string,
    image: string,
    sec_img: string,
    third_img: string,
    video_file:string,
    designed_for: {icon:string,title:string}[],
    specification: {name:string,value:string,additional_hardware:string}[],
    to_lease: string,
    types: string,
    amazon_url: string,
    gem_url:string,
    feature: string,
    size: string,
    brochure_file:string,
    payload:any,
    payload_images: { payload_img: string }[],
    product_urls: { id: number; name: string; url: string; logo: string }[]
  }={id:0,title:'',slug:'',description:'',image:'',sec_img:'',third_img:'',designed_for:[],specification:[],to_lease:'',types:'',amazon_url:'',gem_url:'',feature:'',size:'',brochure_file:'',payload:'',payload_images:[],product_urls:[],video_file:''};

  constructor(private formBuilder:FormBuilder,public modalService: NgbModal,private dataService:DataService,private emailService:EmailService,private authGuard:AuthGuard,private activeRoute: ActivatedRoute,private router: Router,private renderer: Renderer2,){
  }
   // Get unique features
   getUniqueFeatures(): string[] {
    if (!this.product.specification) return [];
    return [...new Set(this.product.specification.map(item => item.additional_hardware))];
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loadProduct(params['slug']);
    });
  }

  loadProduct(slug:string){
    if(slug){
      this.dataService.storeItemDetail(slug).subscribe((data:any)=>{
        if(data.success){
            this.product=data.products;
            this.setActiveImageOnClick(this.product.image);
        }
        else{
          this.router.navigate(['/404'],{skipLocationChange: true});
        }
      },(error: any)=>{
        this.router.navigate(['/404'],{skipLocationChange: true});
      });
    }
  }

  setActiveImageOnClick(path:string){
    this.activeImage = environment.assetUrl+'/'+path;
  }
  
  
  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.assetUrl+'/'+this.product.brochure_file);
    //link.setAttribute('download', `axldrone_general_brochure.pdf`);
    link.click();
    link.remove();
  }
  isVideo(mediaUrl: string): boolean {
    console.log("----------nediautl",mediaUrl)
    // Check if the media URL ends with a video extension
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some((ext) => mediaUrl.endsWith(ext));
  }
  
  @ViewChild('formDialog', { static: true }) formDialog: TemplateRef<any> | undefined;
  buyOnClick(){
    if(this.authGuard.isLoggedin()){
      // send email
      this.emailService.buyEnquiry(this.activeSlug).subscribe((data:any)=>{
        if(data.success){
            this.success_msg = data.msg;
            setTimeout(()=>{
              this.success_msg = null;
            },5000);
          }else{
            this.error_msg = data.msg;
            setTimeout(()=>{
              this.error_msg = null;
            },5000);
          }
        },
        (error: any) => {
          this.error_msg = error.error.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }    
        );
    }else{
      // popup form
      this.modalService.open(this.formDialog,{ centered: true });
    }
  }



  //popup buy now form dialog
  isFormSubmitted: boolean = false;

  @ViewChild('captchaElemPopup') captchaElemPopup: any;
  recaptchaKey: any = environment.recaptchaSiteKey;
  public theme:any= 'dark';
  public size:any= 'normal';
  public lang = 'en';
  public type:any= 'image';

  formGroup:FormGroup = this.formBuilder.group({
    firstName: ['',Validators.required],
    lastName:['',Validators.required],
    email:['',[Validators.required,Validators.email]],
    phone:['',Validators.required],
    address:[''],
    recaptcha:['',Validators.required]
  });

  recaptchaSuccess(data:any) {

  }

  onFormSubmit(){
    this.isFormSubmitted =true;
    if(this.formGroup.invalid){
      return;
    }
   
    this.emailService.buyEnquiryNonLoggedin(
      this.activeSlug,
      this.formGroup.get('firstName')?.value,
      this.formGroup.get('lastName')?.value,
      this.formGroup.get('email')?.value,
      this.formGroup.get('phone')?.value,
      this.formGroup.get('address')?.value,
      this.formGroup.get('recaptcha')?.value
      ).subscribe((data:any)=>{
        if(data.success){
          this.success_msg = data.msg;
          this.captchaElemPopup.resetCaptcha();
          this.formGroup.reset();
          setTimeout(()=>{
            this.modalService.dismissAll();
            this.isFormSubmitted = false;
            this.success_msg = null;
          },5000);
        }else{
          this.isFormSubmitted = false;
          this.error_msg = data.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }    
      );
  }


}
