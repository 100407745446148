<header class="sticky-top">
    <!-- <div class="sticky-panel text-nowrap">
    
        <div class="hstack gap-3">
            <div class=""></div>
            <a [routerLink]="['/axl-store']">
          
                <i class="bi bi-cart3"></i>
                Store</a>
            <a *ngIf="!authGuard.isLoggedin()" [routerLink]="['/login']">
          
                <i class="bi bi-shield-lock"></i>
                Login/Register</a>
            <a *ngIf="authGuard.getPermission('dashboard')" [routerLink]="['/dashboard']">
       
                <i class="bi bi-speedometer2"></i>
                Dashboard</a>
            <a *ngIf="authGuard.getPermission('partner/dashboard')" [routerLink]="['/partner/dashboard']">
         
                <i class="bi bi-speedometer2"></i>
                Dashboard</a>
            <a *ngIf="authGuard.getPermission('partner/change-password')" [routerLink]="['/partner/change-password']">
                <i class="bi bi-key"></i>
                Change Password</a>
            <span *ngIf="authGuard.isLoggedin() && authGuard.getPermission('profile')" class="hstack">Logged in as <div class="username-highlight">{{authGuard.getLoginName()??'unknown'}}</div></span>
            <a *ngIf="authGuard.isLoggedin()" href="javascript:void(0)" (click)="authGuard.logout()"><i class="bi bi-power"></i> Logout</a>
            <div class=""></div>
        </div>
    </div> -->
    
    <div class="main-panel text-nowrap">
        <nav class="navbar navbar-expand-xl bg-faded">
            <div class="container-fluid">
                <!-- Brand Logo -->
                <div class="navbar-brand d-flex me-auto gap-3 " >
                    <a [routerLink]="['/']" >
                        <img class="primary-logo" src="assets/image/axldronelogo.svg" alt="axldrone">        
                    </a>
                    <!-- <div class="my-auto">
                        <img style="width:auto;height: 40px;padding-bottom: 0.1rem;" src="assets/image/msme.png" alt="registered under msme">  
                    </div>
                     -->
                </div>
                <!-- Let's Talk - when Menu Collapse -->
                <button [routerLink]="['/lets-talk']" type="button" class="btn btn-secondary button-custom lets-talk-collapsed">Let's Talk</button>
                <!-- navbar toggle button -->
                <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar3">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse justify-content-center" id="collapsingNavbar3"> -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar3" (click)="toggleMenu()" aria-controls="collapsingNavbar3" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse justify-content-center" id="collapsingNavbar3" [class.show]="menuOpen">
                    <ul class="navbar-nav">
                        <!-- axl-platform -->
                       
                           <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Platform 
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li style="display: flex;">
                               
                                    <a [routerLink]="['/aakash-platform']" class="dropdown-item">      <img src="../../assets/image/aakash.svg"/> &nbsp;aakash</a>
                                </li>
                            </ul>
                        </li>
                      
                        <!-- axl-viman app -->
                       
                  
                        <!-- ---technology--- -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Technology    
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li >
                                    <a [routerLink]="['/viman']" class="dropdown-item"><img src="../../assets/image/axlviman.svg"/> &nbsp;&nbsp;axlviman</a>
                                </li>
                            </ul>
                        </li>
                        <!-- innovation -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Products
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li style="font-size: 18px; line-height: 40.11px; font-weight: 400; padding-left: 30px; cursor: default;"><img src="../../assets/image//byapplication.svg"/> By Application</li>
                                <li *ngFor="let innovation of innovations">
                                    <a [routerLink]="['/innovation',innovation.slug]" class="dropdown-item">{{innovation.title}}</a>
                                </li>
                                <li class="all-products" style="font-size: 18px; line-height: 40.11px; font-weight: 400; padding-left: 30px; cursor: pointer;" [routerLink]="['/product']" ><img src="../../assets/image//byapplication.svg"/>
                                    <a  style="text-decoration: none;"> &nbsp;All Products</a>
                                </li>
                            </ul>
                        </li>
                        <!-- our product -->
                      
                        <!-- drone coe -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Community
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li *ngFor="let coe of coes">
                                    <a [routerLink]="['/drone-coe',coe.slug]" class="dropdown-item">{{coe.name}}</a>
                                </li>
                            </ul>
                        </li>
                        <!-- explore -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Media
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li><a [routerLink]="['/news']" class="dropdown-item"><img src="../../assets/image/byapplication.svg"/>   &nbsp;&nbsp;News</a></li>
                                <li><a [routerLink]="['/events']" class="dropdown-item"><img src="../../assets/image/byapplication.svg"/> &nbsp;&nbsp;Events</a></li>

                            </ul>
                        </li>
                        <!-- partners -->
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Partners 
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li><a [routerLink]="['/partner']" class="dropdown-item">Become a Partner</a></li>
                                <li><a [routerLink]="['/partner/login']" class="dropdown-item">Partner Login</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item">
                            <a [routerLink]="['/about-us']" class="nav-link">About Us</a>
                        </li>
                    </ul>
                </div>
                <!-- Let's Talk - when Menu Expand -->
                <button [routerLink]="['/lets-talk']" type="button" class="btn btn-secondary button-custom lets-talk-expanded">Let's Talk</button>
            </div>
        </nav>
    </div>

    
</header>
<!-- <script>
    $(document).ready(function () {
        $('.dropdown').hover(function () {
            $(this).addClass('show');
            $(this).find('.dropdown-menu').addClass('show');
        }, function () {
            $(this).removeClass('show');
            $(this).find('.dropdown-menu').removeClass('show');
        });
    });
</script> -->