import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-product-buy-modal',
  templateUrl: './product-buy-modal.component.html',
  styleUrls: ['./product-buy-modal.component.scss'],
})
export class ProductBuyModalComponent {
  @Input() platforms: { id: number; name: string; url: string; logo: string }[] = [];
  isOpen = false;
  environment = environment;
  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
