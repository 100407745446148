<!-- <app-popover-component
[showModal]="showModal"
[loading]="loading"
[selectedImage]="selectedImage"
(closeModal)="closeModal()"
(setLoading)="setLoading($event)"
></app-popover-component> -->
<app-layout-header></app-layout-header>
<router-outlet></router-outlet>

<app-layout-footer></app-layout-footer>