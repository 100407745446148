import { Component, OnInit } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-page-news',

  templateUrl: './page-news.component.html',
  styleUrl: './page-news.component.scss'
})
export class PageNewsComponent implements OnInit {
  newsData: any[] = [];
  newsLogo: any[] = [];
  isModalOpen: boolean = false;
  environment=environment;
  modalData: any = null;

  constructor( private dataService:DataService) {}

  ngOnInit(): void {
    // Mock data (replace with API call if needed)
    this.loadItems()
    // const response = {
    //   success: true,
    //   news_data: [
    //     { id: 1, title: "news 1", logo: "assets/logo1.png", slug: "news-1" },
    //     { id: 2, title: "news 2", logo: "assets/logo2.png", slug: "news-2" }
    //   ]
    // };

    // if (response.success) {
    
    // }
  }
  loadItems(){
    this.dataService.newsList().subscribe((data:any)=>{
      if(data.success){
        this.newsData = data.news_data;
       
      }
    });
  }
  openNewsModal(slug: string) {
    this.dataService.getNewsDetails(slug).subscribe((data: any) => {
      if (data.success) {
        this.modalData = data.news_data[0].links; // Ensure links is an array
        this.newsLogo=data.news_data[0].logo
        console.log("dta------",data.news_data[0].links)
        this.isModalOpen = true;
      }
    });
  }
  

  closeModal() {
    this.isModalOpen = false;
    this.modalData = null;
  }

}
