import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageTermsComponent } from './page-terms/page-terms.component';
import { PagePrivacyComponent } from './page-privacy/page-privacy.component';
import { PagePlatformComponent } from './page-platform/page-platform.component';
import { PageInnovationComponent } from './page-innovation/page-innovation.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageProductComponent } from './page-product/page-product.component';
import { PageProductDetailComponent } from './page-product-detail/page-product-detail.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { HttpClientModule } from '@angular/common/http';
import { PageLetstalkComponent } from './page-letstalk/page-letstalk.component';
import { PageUserLoginComponent } from './page-user-login/page-user-login.component';
import { PageUserProfileComponent } from './page-user-profile/page-user-profile.component';
import { PageUserDashboardComponent } from './page-user-dashboard/page-user-dashboard.component';
import { PageStoreComponent } from './page-store/page-store.component';
import { PageLeaseComponent } from './page-lease/page-lease.component';
import { PagePartnerLoginComponent } from './page-partner-login/page-partner-login.component';
import { PagePartnerPasswordResetComponent } from './page-partner-password-reset/page-partner-password-reset.component';
import { PagePartnerDashboardComponent } from './page-partner-dashboard/page-partner-dashboard.component';
import { PageCoeComponent } from './page-coe/page-coe.component';
import { PageCoeEnquiryComponent } from './page-coe-enquiry/page-coe-enquiry.component';
import { PageCareerComponent } from './page-career/page-career.component';
import { PageNewsEventComponent } from './page-news-event/page-news-event.component';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LayoutAddressComponent } from './layout-address/layout-address.component';
import { LayoutWithoutAddressComponent } from './layout-without-address/layout-without-address.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { PagePartnerComponent } from './page-partner/page-partner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesubPartnerRegisterComponent } from './page-partner-login/pagesub-partner-register/pagesub-partner-register.component';
import { PagesubUserOtpComponent } from './page-user-login/pagesub-user-otp/pagesub-user-otp.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagesubAccreditationsComponent } from './page-home/pagesub-accreditations/pagesub-accreditations.component';
import { PageAboutUsComponent } from './page-about-us/page-about-us.component';
import { PopoverComponentComponent } from './components/popover-component/popover-component.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ProductPayloadComponent } from './components/product-payload/product-payload.component';
import { PageShippingpolicyComponent } from './page-shippingpolicy/page-shippingpolicy.component';
import { PageRefundpolicyComponent } from './page-refundpolicy/page-refundpolicy.component';
import { FilterByFeaturePipe } from './components/pipes/filter-by-feature.pipe';
import { PageNewsComponent } from './page-news/page-news.component';
import { ProductBuyModalComponent } from './product-buy-modal/product-buy-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    SanitizeHtmlPipe,
    LayoutComponent,
    LayoutHeaderComponent,
    PageHomeComponent,
    PageTermsComponent,
    PagePrivacyComponent,
    PagePlatformComponent,
    PageInnovationComponent,
    PageContactComponent,
    PageProductComponent,
    PageProductDetailComponent,
    PageNotfoundComponent,
    PageLetstalkComponent,
    PageUserLoginComponent,
    PageUserProfileComponent,
    PageUserDashboardComponent,
    PageStoreComponent,
    PageLeaseComponent,
    PagePartnerLoginComponent,
    PagePartnerPasswordResetComponent,
    PagePartnerDashboardComponent,
    PageCoeComponent,
    PageCoeEnquiryComponent,
    PageCareerComponent,
    PageNewsEventComponent,
    LayoutAddressComponent,
    LayoutWithoutAddressComponent,
    LayoutFooterComponent,
    PagePartnerComponent,
    PagesubPartnerRegisterComponent,
    PagesubUserOtpComponent,
    PagesubAccreditationsComponent,
    PopoverComponentComponent,
    CarouselComponent,
    PageAboutUsComponent,
    ProductPayloadComponent,
    PageShippingpolicyComponent,
    PageRefundpolicyComponent,
    PageNewsComponent,
    ProductBuyModalComponent,
    FilterByFeaturePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
