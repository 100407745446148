import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthGuard } from '../service-guard/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient,private authGuard:AuthGuard) { }

  /* send Lets Talk email */
  letsTalk(name: string, email: string, phone: string, message: string, recaptcha: string){
    return this.http.post<any>(`${environment.apiUrl}/lets-talk`,
    {
      name:name, 
      email:email, 
      phone:phone,
      message:message, 
      recaptcha_response:recaptcha
    });
  }
  
  /* send contact-us email */
  contactUs(firstName: string,lastName: string, email: string, message: string, recaptcha: string){
    let body={
      first_name:firstName, 
      last_name:lastName, 
      email:email, 
      message:message,
      recaptcha_response:recaptcha
    };
    return this.http.post<any>(`${environment.apiUrl}/contact-us`,body);
  }

  /* send COE Enquiry email */
  coeEnquiry(coeSlug: string,fullName: string, email: string, phone: string, message: string, recaptcha: string){
    return this.http.post<any>(`${environment.apiUrl}/coe-enquiry`,
    {
      name:fullName,  
      email:email, 
      phone:phone, 
      message:message,
      recaptcha_response:recaptcha
    },
    {
      params:{
        slug:coeSlug
      }
    });
  }

  /* send partner Enquiry email */
  partnerEnquiry(companyName: string,companyAddress: string, phoneCountry: string, phone:string, email: string, recaptcha: string){
    let body={
      company_name:companyName, 
      company_address:companyAddress, 
      phone_country_code: phoneCountry,
      phone:phone,
      email:email,
      recaptcha_response:recaptcha
    }
    return this.http.post<any>(`${environment.apiUrl}/add-partner`,body);
  }
  
  /* send buy Enquiry email */
  buyEnquiry(productSlug: string){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    let body={
      product_slug:productSlug
    }
    return this.http.post<any>(`${environment.apiUrl}/drone-buy`,body,{headers:header});
  }

  /* send Lease Enquiry email */
  leaseEnquiry(productSlug: string,payload: string[],customPayload: string,pilotRequired: string){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    let body={
      product_slug: productSlug,
      productfeature__title: payload,
      extra_features: customPayload,
      pilot_required: pilotRequired
    }
    return this.http.post<any>(`${environment.apiUrl}/axl-store-lease`,body,{headers:header});
  }

  /* buynow email for non-loggedin */
  buyEnquiryNonLoggedin(productSlug: string,firstName: string,lastName: string, email: string, phone: string, delivery_address: string, recaptcha: string){
    return this.http.post<any>(`${environment.apiUrl}/non-logged-in-drone-buy`,
    {
      drone_slug:productSlug,
      first_name:firstName, 
      last_name:lastName, 
      email:email, 
      phone:phone,
      delivery_address:delivery_address, 
      recaptcha_response:recaptcha
    });
  }

}


